import { getStoreAccessors } from 'typesafe-vuex';
import { ActionContext } from 'vuex';
import { State } from '../state';
import { AppState } from './state';
import {
  commitShowToast,
  commitHideToast,
  commitSetCurrentCurrency,
} from './mutations';
import {
  readToast,
} from './getters';

type AppContext = ActionContext<AppState, State>;

export const actions = {

  actionShowToast(context: AppContext, message: string) {
    const toast = readToast(context);
    if (toast.show) {
      commitHideToast(context);
    }
    setTimeout(() => {
      commitShowToast(context, {
        color: 'black',
        message,
        timeout: 5000,
      });
    });
  },

  showStandardErrorToast(context: AppContext, { error }) {
    const toast = readToast(context);
    if (toast.show) {
      commitHideToast(context);
    }
    if (error !== undefined && error.message && process.env.VUE_APP_ENV === 'development') {
      setTimeout(() => {
        commitShowToast(context, {
          color: 'error',
          message: error.message,
          timeout: 5000,
        });
      });
    } else {
      setTimeout(() => {
        commitShowToast(context, {
          color: 'error',
          // message: 'Es ist etwas schief gelaufen, es tut uns leid. Bitte versuche es zu einem späteren Zeitpunkt noch einmal oder melde dich an support@kiresult.com.',
          message: 'Something went wrong, we are sorry. Please try again at a later date or contact us at support@kiresult.com.',
          timeout: 5000,
        });
      });
    }
  },

  showErrorToast(context: AppContext, message) {
    const toast = readToast(context);
    if (toast.show) {
      commitHideToast(context);
    }
    setTimeout(() => {
      commitShowToast(context, {
        color: 'error',
        message,
        timeout: 5000,
      });
    });
  },

  showAxiosErrorToast(context: AppContext, message) {
    const toast = readToast(context);
    if (toast.show) {
      commitHideToast(context);
    }
    setTimeout(() => {
      if (message['response'] && message['response']['status'] == 422 && message['response']['data']['detail']) {
        let finalMessage: string = '';
        for (let mes of message['response']['data']['detail']) {
          finalMessage = `${finalMessage} ${mes.msg}`
        }
        commitShowToast(context, {
          color: 'error',
          message: finalMessage,
          timeout: 5000,
        });
      } else if (message['response'] && message['response']['status'] == 400 && message['response']['data']['detail']) {
        commitShowToast(context, {
          color: 'error',
          message: message['response']['data']['detail'],
          timeout: 5000,
        });
      }
      else {
        commitShowToast(context, {
          color: 'error',
          message,
          timeout: 5000,
        });
      }
    });
  },

  showSuccessToast(context: AppContext, message) {
    const toast = readToast(context);
    if (toast.show) {
      commitHideToast(context);
    }
    setTimeout(() => {
      commitShowToast(context, {
        color: 'success',
        message,
        timeout: 5000,
      });
    });
  },
  setCurrentCurrency(context: AppContext, currencyLabel: string) {
    const currFilter = context.state.availableCurrencies.filter(curr => curr['label'] == currencyLabel);
    if (currFilter.length == 1) {
      commitSetCurrentCurrency(context, currFilter[0]);
    } else {
      // TODO errorhandling, if currency is not found. For now it leaves default currency.
    }
  },
};

const { dispatch } = getStoreAccessors<AppState | any, State>('');

export const dispatchShowToast = dispatch(actions.actionShowToast);
export const dispatchShowStandardErrorToast = dispatch(actions.showStandardErrorToast);
export const dispatchShowErrorToast = dispatch(actions.showErrorToast);
export const dispatchShowAxiosErrorToast = dispatch(actions.showAxiosErrorToast);
export const dispatchShowSuccessToast = dispatch(actions.showSuccessToast);
export const dispatchSetCurrentCurrency = dispatch(actions.setCurrentCurrency);
