import { getStoreAccessors } from 'typesafe-vuex';
import { ActionContext } from 'vuex';
import { State } from '../state';

// API
import { api } from '@/api';
import { IInsightsState } from './state';
import { dispatchShowStandardErrorToast } from '../app/actions';
import { getCurrentFilterPeriods, getDimensionFilters } from '@/functions/researchFilterFunctions';
import { IInsightConfig, insightsConfig, insightsConfigSingle, insightsFavourites } from '@/interfaces/insights';
import { formatNumber } from '@/filters/formatCurrency';


type InsightsContext = ActionContext<IInsightsState, State>;

export function getInsightConfig(insightKey: string): IInsightConfig {
  return insightsConfig[insightsConfigSingle[insightKey].parentInsight];
}

export const actions = {
  async loadInsightsTypes(context: InsightsContext) {
    try {
      const response = await api.insightsTypes(context.rootState.main.token);
      if (response.data) {
        context.state.insightsTypes = response.data;
        for (const type of context.state.insightsTypes) {
          context.state.insightsData.push({
            insight: type,
            dataLoaded: false,
            dataLoading: true,
            expanded: false,
            data: [],
          });
        }
        context.state.insightsTypesLoaded = true;
      }
    } catch (err) {
      dispatchShowStandardErrorToast(context, { error: err });
    }
  },
  async calculateSingleInsight(context: InsightsContext, payload: { type: string, store: any }) {
    try {
      let index = context.state.insightsData.findIndex(e => e.insight == payload.type);
      if (index > -1) {
        context.state.insightsData[index].dataLoaded = false;
        context.state.insightsData[index].dataLoading = true;
        const response = await api.insightsCalculate(
          context.rootState.main.token,
          payload.type,
          getCurrentFilterPeriods(payload.store)[0],
          getCurrentFilterPeriods(payload.store)[1],
          getDimensionFilters(payload.store)[0],
          getDimensionFilters(payload.store)[1],
          getDimensionFilters(payload.store)[2],
          getDimensionFilters(payload.store)[3]
        );

        if (response.data) {
          context.state.insightsData[index].data = response.data;
          context.state.insightsData[index].dataLoading = false;
          context.state.insightsData[index].dataLoaded = true;
          dispatchSetExplainationStrings(context, { type: payload.type, topN: 1 });
        }
      }
    } catch (err) {
      dispatchShowStandardErrorToast(context, { error: err });
    }
  },
  async calculateFavouriteInsights(context: InsightsContext, payload: { store: any }) {
    try {
      let promises: any[] = [];
      for (let insight of context.state.insightsData) {
        if (insightsFavourites.includes(insight.insight)) promises.push(dispatchCalculateSingleInsight(context, { type: insight.insight, store: payload.store }));
      }
      await Promise.all(promises);
    } catch (err) {
      dispatchShowStandardErrorToast(context, { error: err });
    }
  },
  async setExplainationStrings(context: InsightsContext, payload: { type: string, topN: number }) {
    try {
      let index = context.state.insightsData.findIndex(e => e.insight == payload.type);
      if (index > -1) {
        if (context.state.insightsData[index].data.length > 0) {
          let insightConfig = getInsightConfig(context.state.insightsData[index].insight);
          let topExplainationValueRounding: number = (insightConfig.insightExplainationValueRounding !== undefined) ? insightConfig.insightExplainationValueRounding : 1;
          for (let i = 0; i < payload.topN; i++) {
            let topExplaination = insightConfig.insightPlaceholderTopExplaination;
            topExplaination = topExplaination.replaceAll('{{ value }}', formatNumber(context.state.insightsData[index].data[i].value, topExplainationValueRounding).toString());
            topExplaination = topExplaination.replaceAll('{{ name }}', context.state.insightsData[index].data[i].name);
            topExplaination = topExplaination.replaceAll('{{ referenceToX }}', insightsConfigSingle[context.state.insightsData[index].insight].referenceToX);
            let referenceToXPlural: string | undefined = insightsConfigSingle[context.state.insightsData[index].insight].referenceToXPlural;
            if (referenceToXPlural) topExplaination = topExplaination.replaceAll('{{ referenceToXPlural }}', referenceToXPlural);
            context.state.insightsData[index].data[i].explainationString = topExplaination;

            if (i == context.state.insightsData[index].data.length - 1) break;
          }
        }
      }

    } catch (err) {
      dispatchShowStandardErrorToast(context, { error: err });
    }
  }
};

const { dispatch } = getStoreAccessors<IInsightsState | any, State>('');

export const dispatchLoadInsightsTypes = dispatch(actions.loadInsightsTypes);
export const dispatchCalculateSingleInsight = dispatch(actions.calculateSingleInsight);
export const dispatchCalculateFavouriteInsights = dispatch(actions.calculateFavouriteInsights);
export const dispatchSetExplainationStrings = dispatch(actions.setExplainationStrings);
